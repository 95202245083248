.sidebar-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  margin: 1rem 0 2rem 0;
  padding: 0 1rem 1rem 0;
}

.sidebar-container-title {
  padding: 5px 0;
  cursor: pointer;
  background-color: var(--dblue);
  text-align: center;
  border-radius: 3px;
}

.sidebar-container-title-link {
  color: #FFF;
  font-size: 13px;
  text-decoration: none;
}

.sidebar-container-title-link:hover {
  text-decoration: none;
}

.sidebar-container li {
  cursor: pointer;
  font-size: 13px;
  transition: 0.3s;
  padding: 0.5rem 3px;
  user-select: none;
  border-radius: 2px;

  &.selected {
    font-weight: bold;
    color: #FFF;
    background-color: var(--dorange);
  }

  &:not(:first-of-type) {
    border-top: 1px solid var(--bordergrey);
  }
}


li.mainCategory:hover,
li.subCategory:hover,
li.lan:hover,
li.ort:hover {
  background-color: var(--bordergrey);
  color: #333;
}

li.ort {
  display: none;
  padding-left: 1rem;
}

li.ort.open {
  display: block;
}

li.subCategory {
  display: none;
  padding-left: 1rem;
}

li.subCategory.open {
  display: block;
}

li.subSubCategory {
  display: none;
  padding-left: 2rem;
}

li.subSubCategory.open {
  display: block;
}

.link {
  color: black;
}

.link:hover {
  color: black;
}

.sidebar-findme-wrapper {
  color: var(--dorange);

  &:hover {
    background-color: var(--dorange);
    color: white;
  }
}

.sidebar-findme {
  display: flex;
  align-items: center;
  color: inherit;

  svg {
    height: 1.2rem;
  }
}

#sidebar-container-orter {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .sidebar-container {
    position: fixed;
    max-width: 640px;
    width: 80vw;
    height: calc(100vh - 80px);
    left: 0px;
    top: 80px;
    background: white;
    margin-left: 0;
    margin-top: 0;
    transform: translateX(-100vw);
    transition: 0.2s;
    overflow-y: auto;
    padding-left: 1rem;
    z-index: 3;

    li {
      font-size: 1rem;
      padding-top: 0.5rem !important;
      // padding-bottom: 0.5rem !important;
    }

    .sidebar-container-title {
      font-size: 1rem;
      border-radius: 0;
    }
  }
  .sidebar-container.open {
    transform: translateX(0);
    box-shadow: 20vw 0 0 0 rgba(0,0,0,0.2);
  }
  #sidebar-container-title-kategori {
    margin-top: 20px;
  }
  #sidebar-container-orter {
    padding-bottom: 4rem;
  }
}

