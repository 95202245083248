.chat_header_container {
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  display: grid;
  grid-template-columns: 65px auto;
  flex-direction: row;
  padding: 10px;
  // border-bottom: 1px solid var(--lblue);
  box-sizing: border-box;
}

.chat_header_container-profilepicture {
  position: relative;
  width: 51px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}
.chat_header_container-profilepicture-go-text {
  font-size: 12px;
  display: grid;
  width: 100%;
  color: var(--lblue);
  cursor: pointer;
  grid-template-columns: 50% 50%;

  @media( min-width: 768px ) {
    grid-template-columns: unset;
  }

  p {
    width: 100%;
  }

  p.align-right {
    text-align: right;
  }

  @media(min-width: 768px) {
    p.hideForDesktop {
      display: none;
    }
  }
}

.chat_header_container-name-adress {
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--lblue);
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}

.chat_header_container-gotoad {
  width: 91px;
  height: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: rgb(255, 159, 103);
  box-sizing: border-box;
  padding-bottom: 25px;
  margin-right: 150px;
}

.chat_header_container-phone {
  width: 110px;
  height: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: rgb(255, 159, 103);
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.chat_header_container-phone-icon {
  font-family: Montserrat;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: auto;
  margin: auto;
  display: flex;
  width: 130px;
  height: 31px;
  color: #ffffff;
}

@media screen and (max-width: 920px) {
  .chat_header_container-profilepicture {
    margin-left: 0px;
    margin-right: 0px;
  }
  .chat_header_container-gotoad {
    margin-left: 0px;
    margin-right: 0px;
  }
  .chat_header_container-name-adress {
    margin-left: 0px;
    margin-right: 0px;
  }

  .chat_header_container{
    padding:0;
  }

  .chat_header_container-name-adress{
    padding:0;
  }
}
