.footer-container {
  background-color: var(--dblue);
  bottom: 0px;
  padding: 1rem 1rem 1rem 1rem;
  box-sizing: border-box;
  height: fit-content;
}

.footer-column-logo {
  width: 20%;
}

.footer-column-information {
  width: 70%;
}

.footer-column-icons {
  width: 10%;
  text-align: right;
}

.footer-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.footer-links-1 {
  margin: 0;
}

.logo-with-text {
  width: 100%;
  max-width: 120px;
}

.footer-links {
  font-size: 12px;
  cursor: pointer;
}

.footer-link,
.footer-link-title {
  color: white !important;
  padding: 4px;
  border-radius: 2px;
}

.footer-link {
  transition: 0.3s;
}

.footer-link:hover {
  color: var(--dorange) !important;
}

.footer-links-title {
  padding-top: 10px;
  font-size: 10px;
  font-weight: bold;
}

.footer-links-1-text {
  padding-left: 8px;
  font-size: 11px;
  color: white;
  line-height: 1.2;
}

.footer-icons-container {
  padding: 10px;
  list-style: none;
  cursor: pointer;
}

.footer-icons-instagram,
.footer-icons-facebook {
  color: white;
  width: 24px;
}

.footer-column-information ul {
  list-style: none;
  margin: 0;
}

.footer-column-information li {
  display: inline;
  font-size: 11px;
  padding: 0 8px;
  border-right: 1px solid #fff;
}

.footer-column-information li:last-child {
  border-right: 0;
}

@media screen and (max-width: 920px) {
  .footer-row {
    flex-direction: column;
    margin: 0;
    text-align: center;
    padding: 1rem 0;
  }

  .footer-column-logo {
    width: 100%;
  }

  .footer-links-1-text {
    padding-left: 0;
  }

  .footer-column-information {
    width: 100%;
    margin: 1rem 0;
    ul {
      padding: 0;
      flex-wrap: wrap;
      justify-content: center;
      li {
        display: block;
        border-right: none;
      }
    }
  }

  .footer-column-company,
  .footer-column-icons {
    width: 100%;
  }

  .footer-column-icons {
    text-align: center;
  }
}
