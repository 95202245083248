ul {
  list-style: none;
}

li.navbar-category-underlinks {
  padding-left: 0;
}

.categories-icons {
  background: var(--dblue);
  width: 100%;
  z-index: 3;
  position: fixed;
  text-align: center;
  display: none;
  // padding-bottom:5px;
  justify-content: space-between;
}

.categories-icons-links {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.category-icons-links:hover {
  background-color: var(--dblue);
}

.categories-icons-logos {
  width: 33px;
}

.categories-icons-text {
  font-size: 12px;
  color: #ffffff;
  text-overflow: ellipsis;
  overflow: hidden;
}

.categories-icons-menu {
  list-style-type: none;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
}

.categories-icons-links-a {
  font-size: 17px;
  line-height: 1.41;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(54, 52, 51);
}



.category-container {
  padding: 0;
}

.navbar-chosen-category {
  padding: 0;
}

.icons-only {
  padding: 6px;
  height: 40px;
}

.categories-icons-container {
  font-size: 17px;
  line-height: 1.41;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(54, 52, 51);
}

.navbar-container-wrapper {
  width: 100%;
  z-index: 2;
  position: fixed;
  top:15px;
}

.navbar-fashion-container,
.navbar-home-container,
.navbar-kids-container,
.navbar-hobbies-container,
.navbar-electronics-container,
.navbar-pets-container,
.navbar-vehicles-container,
.navbar-living-container {
  width: 100%;
  min-height: 440px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: 0px 0px 0px 4000px rgba(0, 0, 0, 0.5);
}

.navbar-fashion-container {
  background-image: url("https://images.unsplash.com/photo-1508189860359-777d945909ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
  background-position: 60% 30%;
}

.navbar-home-container {
  background-image: url("https://images.unsplash.com/photo-1526308182272-d2fe5e5947d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80");
  background-position: 15% 50%;
}

.navbar-kids-container {
  background-image: url("https://images.unsplash.com/photo-1531325082793-ca7c9db6a4c1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80");
  background-position: -21% 99%;
}

.navbar-hobbies-container {
  background-image: url("https://images.unsplash.com/photo-1587683437366-a85b816f6e5a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80");
  background-position: 50% 60%;
}

.navbar-electronics-container {
  background-image: url("https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80");
  background-position: left center;
}

.navbar-pets-container {
  background-image: url("https://images.unsplash.com/photo-1548199973-03cce0bbc87b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
  background-position: left center;
}

.navbar-vehicles-container {
  background-image: url("https://images.unsplash.com/photo-1599834866654-76cee31c30c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80");
  background-position: left center;
}

.navbar-living-container {
  background-image: url("https://images.unsplash.com/photo-1566029655254-716ba8f7e766?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80");
  background-position: right center;
}

.navbar-wrapper {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  height: 100%;
  display: flex;
}

.navbar-dropdown-ul {
  width: 100%;
  max-width: 250px;
  height: fit-content;
  max-height: 100%;
  background-color: white;
  border: 1px solid rgb(208, 208, 208);
  overflow-y: scroll;
  padding-left: 0;
}

.navbar-dropdown-ul::-webkit-scrollbar {
  display: none;
}

.navbar-container-margin-left-region {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1508189860359-777d945909ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
  background-position: left left;
}

.navbar-container-margin-left-fashion {
  height: 100%;
  width: 100px;
  float: left;
  background-color: rgb(29, 226, 253);
}

.navbar-container-margin-left-home {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1526308182272-d2fe5e5947d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80");
  background-position: 15% 50%;
}

.navbar-container-margin-left-kids {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1531325082793-ca7c9db6a4c1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80");
  background-position: left center;
}

.navbar-container-margin-left-hobbies {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1587683437366-a85b816f6e5a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80");
  background-position: left center;
}

.navbar-container-margin-left-electronics {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80");
  background-position: left center;
}

.navbar-container-margin-left-pets {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1548199973-03cce0bbc87b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
  background-position: left center;
}

.navbar-container-margin-left-vehicles {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1599834866654-76cee31c30c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80");
  background-position: left center;
}

.navbar-container-margin-left-living {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1566029655254-716ba8f7e766?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80");
  background-position: right center;
}

.navbar-container-margin-left-luxury {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1490114538077-0a7f8cb49891?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80");
  background-position: left top;
}

.navbar-container-margin-left-other {
  height: 100%;
  width: 100px;
  float: left;
  background-image: url("https://images.unsplash.com/photo-1570300315788-5d3c46681461?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1522&q=80");
  background-position: left top;
}

.navbar-category-container {
  height: 100%;
  float: left;
  width: 300px;
  padding-top: 10px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 1px 1px;
}

.navbar-category-link {
  padding: 10px 10px 10px 20px;
}

.navbar-category-link-extended-icon {
  float: right;
  color: #606060;
}

.navbar-category-links {
  letter-spacing: 0.5px;
  height: fit-content;
  font-size: 0.75rem;
  cursor: pointer;
  color: #505050;
  text-decoration: none;
  text-align: left !important;
  list-style: none;
}

.navbar-category-underlinks {
  padding: 4px 4px 4px 30px;
  font-size: 0.68rem;
}

.navbar-category-content {
  width: calc(100% - 400px);
  float: left;
  height: 450px;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
}

.navbar-category-content-special {
  width: calc(100% - 100px);
  float: left;
  height: 450px;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
}

.navbar-container-text {
  position: relative;
  background: #eee;
  overflow: hidden;
  width: 50%;
}

.navbar-image-container-region,
.navbar-image-container-fashion,
.navbar-image-container-home,
.navbar-image-container-kids,
.navbar-image-container-hobbies,
.navbar-image-container-electronics,
.navbar-image-container-pets,
.navbar-image-container-vehicles,
.navbar-image-container-living,
.navbar-image-container-luxury,
.navbar-image-container-other {
  width: 100%;
  height: 100%;
  float: left;
  background-repeat: no-repeat;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

.navbar-image-container-region {
  background-image: url("https://images.unsplash.com/photo-1508189860359-777d945909ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
}

.navbar-image-container-fashion {
  background-image: url("https://i.imgur.com/jgQsD1a.png");
  background-position: 100% 10%;
}

.navbar-image-container-home {
  background-image: url("https://images.unsplash.com/photo-1526308182272-d2fe5e5947d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80");
  background-position: center center;
}

.navbar-image-container-kids {
  background-image: url("https://images.unsplash.com/photo-1531325082793-ca7c9db6a4c1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80");
  background-position: 20% 60%;
}

.navbar-image-container-hobbies {
  background-image: url("https://images.unsplash.com/photo-1587683437366-a85b816f6e5a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80");
  background-position: center center;
}

.navbar-image-container-electronics {
  background-image: url("https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80");
  background-position: 30% 0%;
}

.navbar-image-container-pets {
  background-image: url("https://images.unsplash.com/photo-1548199973-03cce0bbc87b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
  background-position: center center;
}

.navbar-image-container-vehicles {
  background-image: url("https://images.unsplash.com/photo-1599834866654-76cee31c30c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80");
  background-position: center center;
}

.navbar-image-container-living {
  background-image: url("https://images.unsplash.com/photo-1566029655254-716ba8f7e766?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80");
  background-position: center center;
}

.navbar-image-container-luxury {
  background-image: url("https://images.unsplash.com/photo-1490114538077-0a7f8cb49891?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80");
  background-position: center center;
}

.navbar-image-container-other {
  background-image: url("https://images.unsplash.com/photo-1570300315788-5d3c46681461?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1522&q=80");
  background-position: center center;
}

.navbar-category-text-title {
  padding: 35px 0 20px 30px;
  font-size: 16px;
  color: #606060;
  cursor: pointer;
}

.navbar-regions-text-container h1,
.navbar-container-text p {
  padding: 50px;
  text-align: center;
  justify-content: center;
  justify-items: center;
  z-index: 2;
  position: relative;
  color: black;
}

.hide {
  display: none;
}

.navbar-home-text {
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 1);
}

.navbar-category-text {
  padding: 30px;
  z-index: 1;
  width: 50%;
  float: left;
  letter-spacing: 0.92px;
  height: 100%;
  background: rgba(255, 255, 255, 1);
}

.navbar-home-text {
  padding: 20px 20px 20px 0px;
}

.category-expander {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar-fashion-container,
  .navbar-home-container,
  .navbar-kids-container,
  .navbar-hobbies-container,
  .navbar-electronics-container,
  .navbar-pets-container,
  .navbar-vehicles-container,
  .navbar-living-container {
    background-image: unset;
  }
  
  .navbar-category-container {
    width: 100%;
  }

  .navbar-wrapper, .navbar-dropdown-ul {
    max-width: unset;
  }

  .navbar-category-link {
    text-align: center;
  }

  .navbar-container-text {
    display: none;
  }

  .categories-icons {
    background: var(--dorange);
    padding-top: 100px;
    transition: 0.2s;
  }

  .categories-icons-menu {
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;

    li {
      width: 33%;
    }
  }

  .category-expander {
    display: none;
    align-items: center;
    justify-content: center;
    color: white;

    svg {
      margin: 3px 0;
    }
  }
}
