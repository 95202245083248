textarea.report {
  border: 1px solid grey;
  border-radius: 3px;
  resize: none;
}

button.submit {
  background-color: var(--dorange);
  color: white;
}
