nav.responsive-toolbar {
  background: #ffffff;
  height: 100%;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;

  .sidebar-hamburger {
    display: none;
  }

  ul {
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    height: 100%;
    align-items: center;

    figure {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 10px;
      margin-inline-end: 0;
      cursor: pointer;
    }

    li {
      display: flex;
      list-style-type: none;
      margin: 7px;
      //font-size: 0.9rem;
      padding: 3px;

      .active {
        background: #999;
      }
    }

    a {
      text-decoration: none;
      display: flex;
      // flex-direction: row-reverse;
      align-items: center;
    }

    i {
      font-size: 15px;
    }
  }
}

.MuiButton-contained {
  background-color: transparent;
  box-shadow: none !important;
}

button[type="button"] {
  background-color: #ffffff;
  color: var(--dgrey);
  border-radius: 4px;
  margin-right: 10px;
  &:hover {
    background-color: var(--dorange);
    color: white;
  }
}

.link-text-message,
.link-text-icon {
  padding: 5px;
}

.navbar-dropdown-menu {
  // border: 1px solid var(--dgrey);
  border-radius: 20%;
  cursor: pointer;
  background-color: var(--dblue);
}

.navbar-dropdown-menu-icon {
  color: white;
  width: 31px !important;
  height: 31px !important;
}

.navbar-dropdown-menu-icon:hover {
}

.dropdown {
  background-color: #f2f3f5;
  border-radius: 10px;
  padding: 5px;
}

.link-text-message:hover {
  color: rgb(233, 85, 37);
}

.link-text-icon:hover {
  color: rgb(189, 186, 186);
}

.btn {
  flex: 1 1 auto;
  margin: 10px;
  padding: 7px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  font-weight: bold;
}

.btn:hover {
  background-image: linear-gradient(to right, #e85138 0%, #e85138 100%);
}

.btn-1 {
  background-image: linear-gradient(to right, #f6593d 0%, #f6593d 100%);
  padding: 10px;
}

.create-annons-button {
  background-image: linear-gradient(to right, #f6593d 0%, #f6593d 100%);
  vertical-align: middle;
  border: none;
}

.annons {
  flex: 1 1 auto;
  margin: 10px;
  padding: 7px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  border-radius: 55px;
  text-decoration: none;
  list-style: none;
}

.annons:hover {
  background-image: linear-gradient(to right, #e85138 0%, #e85138 100%);
}

.annons-text {
  background-image: linear-gradient(to right, #f6593d 0%, #f6593d 100%);
}

.add-icon,
.login-icon {
  vertical-align: bottom;
  color: white;
}

.right-container {
  padding-right: 20px;
  padding-left: 10px;
  width: 100%;
  max-width: fit-content;
}
.link-text-icon-notification {
  padding-bottom: 12px;
}

.right-container-toggle {
  cursor: pointer;
  padding-right: 20px;
  padding-left: 10px;
  svg.menu-icon-right {
    color: var(--dorange);
  }
}

.middle-container {
  text-align: center;
  padding-right: 20px;
  width: 100%;
  margin: 0 auto;
}

.searchfield {
  display: inline-block;
  width: 100%;
  max-width: 600px;
  z-index: 1;

  .searchfield-icon-container.search-made {
    background-color: var(--dorange);
    color: white;
  }

  input.searchfield[type="text"].search-made {
    background-color: lightyellow;
  }
}

.searchfield-container {
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.searchfield-result-list {
  display: block !important;
  display: flex;
  width: 100%;
}

.searchfield-result-container {
  display: flex;
  vertical-align: middle;
  background-color: white;
  border: 1px solid rgb(198, 194, 194);
  width: 100%;
  min-width: 100px;
  max-width: 600px;
  height: fit-content;
  text-align: left;
  cursor: pointer;
  position: fixed;
  z-index: 1;
}

.searchfield-result-link {
  border-top: 1px solid rgb(198, 194, 194);
  padding: 10px;
  margin: 0 !important;
  z-index: -1 !important;
}

.searchfield-result-link-active {
  background-color: #eee;
}

.searchfield-result-link:hover {
  background-color: #eee;
}

input.searchfield[type="text"] {
  border-top: 1px solid rgb(198, 194, 194);
  border-left: 1px solid rgb(198, 194, 194);
  border-bottom: 1px solid rgb(198, 194, 194);
  border-right: 0;
  background-color: white;
  width: calc(100% - 20px);
  min-width: 100px;
  max-width: 600px;
  height: 33px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  // @media(max-width:768px) {
  //   height: 30px;
  // }
}

input.searchfield[type="text"]:read-write {
  padding-left: 10px;
  color: #101010;
}

.searchfield-regions-container {
  height: 40px;
  background-color: white;
  border-top: 1px solid rgb(198, 194, 194);
  border-bottom: 1px solid rgb(198, 194, 194);
  display: table;
}

.searchfield-regions {
  display: table-cell;
  vertical-align: middle;
}

select.searchfield-regions-select {
  border: none;
  width: 130px;
  padding-left: 5px;
  margin-right: 3px;
  height: 26px;
  border-left: 1px solid rgb(198, 194, 194);
  cursor: pointer;
  background: transparent;
}

.searchfield-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(198, 194, 194);
  background-color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 45px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--lblue);
    color: white;
  }
}

.searchfield-icon {
  display: flex;
  vertical-align: middle;
}

.left-container {
}

.links {
  display: flex;
}

.dropdown-container {
  display: none !important;
}

.dropdown-container-active {
  display: block !important;
  background-color: white;
  position: absolute;
  width: 100%;
  max-width: 300px;
  top: 60px;
  right: 0px;
  height: auto !important;
  z-index: 1;
  box-shadow: -50vw 50vw 0 100vw rgba(0, 0, 0, 0.25);
}

.dropdown-login-container {
  background-color: white;
  position: absolute;
  width: 100%;
  max-width: 150px;
  top: 60px;
  right: 0px;
  height: auto !important;
  z-index: 1;
  display: block !important;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.25);
}

.image-logo {
  margin: 0 20px;
  cursor: pointer;
  padding-right: 0px;
}

.plus-icon {
  color: rgb(250, 27, 27);
  font-size: 20px;
}

li a,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
}

li.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 130px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.link-text {
  padding: 3px;
  vertical-align: super;
}

.navbar-dropdown-divider {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-links {
  color: var(--dgrey);
  text-decoration: none;
  list-style: none;

  .MuiSvgIcon-root {
    font-size: 36px;
  }

  @media( max-width: 768px ) {
    .MuiSvgIcon-root {
      font-size: 31px;
    }
  }
}

.navbar-links-not-logged {
  color: white;
  text-decoration: none;
  list-style: none;
}

.not-logged {
}

.heart {
  color: red;
}

.heart:hover {
  color: lightcoral;
}

.dropdown-a {
  text-align: center;
}

.dropdown-a:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.profile-picture {
  border-radius: 20%;
  width: 36px;
  height: 36px;
  object-fit: cover;
  vertical-align: text-top;

  @media( max-width: 768px ) {
    width: 31px;
    height: 31px;
  }
}

.navbar-login-button {
  color: white;
  text-decoration: none;
}

.create-annons {
  border: none;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
}

.hide {
  display: none;
}

.menu-icon-right {
  position: fixed;
  top: 0;
  right: 0;
  margin: 19px;
  font-size: 24px;
  cursor: pointer;
  color: whitesmoke;
}

.menu-icon-left {
  position: fixed;
  top: 0;
  left: 0;
  margin: 19px;
  font-size: 24px;
}

.cancel-icon {
  position: fixed;
  top: 0;
  right: 0;
  margin: 10px;
  color: rgb(206, 59, 59);
}

.navbar-notification-icon {
  color: var(--dgrey);
  cursor: pointer;
  font-size: 31px;
}

.navbar-notification-icon:hover {
  color: rgb(233, 85, 37);
}

.menu-tabs {
  padding: 10px;
  color: #707070;
  cursor: pointer;
  letter-spacing: 0.65px;
}

.menu-tabs:hover {
  background-color: #f1f1f1;
}

.navbar-login-choices {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  text-align: center;
}

.navbar-google-container,
.navbar-facebook-container {
  width: 100%;
  padding: 10px;
}

.btn-google,
.btn-fb {
  display: inline-block;
  border-radius: 1px;
  text-decoration: none;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}

.btn-google:hover,
.btn-fb:hover {
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.25);
}

.navbar-google-img,
.navbar-fb-img {
  width: 35px;
}

.navbar-google-content,
.navbar-fb-content {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-fb-text,
.navbar-google-text {
  padding: 10px;
  font-style: normal;
  color: #504040;
}

.navbar-login-form-email {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin: 10px;
}

.navbar-login-form-email:hover {
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.navbar-no-account {
  font-size: 12px;
  font-style: italic;
  text-align: center;
}

.navbar-between {
  font-size: 12px;
  font-style: normal;
  text-align: center;
  padding: 10px;
}

.navbar-dropdown-block {
  display: flex;
  align-items: center;
}

.navbar-dropdown-icons {
  background-color: rgb(228, 230, 235);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-dropdown-avatar {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.navbar-dropdown-text {
  font-weight: bold;
  margin-left: 10px;
}

.navbar-dropdown-text-avatar {
  margin-left: 10px;
}

datalist {
  background-color: var(--lgrey);
  color: black;
  font-size: 0.9rem;
}

datalist > option {
  background-color: var(--lgrey);
  color: black;
  font-size: 0.9rem;
}

button.login-btn {
  color: white;
  background-color: var(--dblue);
  text-transform: none;
  padding: 6px 16px;

  &:hover {
    //background-color: var(--lblue);
  }

  &:active,
  &:visited {
    background-color: var(--dblue);
  }
}

button.create-ad-btn {
  background-color: var(--dorange);
  color: white;
  padding: 6px 16px;
  text-transform: none;

  &:hover {
    background-color: var(--dblue);
  }
}

.profile-btn {
  color: var(--dgrey);
  text-decoration: none;
}

.profile-btn:hover {
  color: var(--orange);
}
@media screen and (max-width: 768px) {
  nav.responsive-toolbar .sidebar-hamburger {
    display: flex;
    height: 35px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #c6c2c2;
  }
}

@media screen and (max-width: 768px) {
  nav.responsive-toolbar {
    flex-wrap: wrap;
    height: 100%;
    padding-top: 10px;
    align-self: start;

    .middle-container {
      order: 1;
      padding: 7px;
      flex-grow: 2;
      display: grid;
      grid-template-columns: 40px auto;
      align-items: center;
      gap: 5px;
    }
    
    .left-container {
      width: unset;
      flex-grow: 1;
    }
    
    .right-container {
      width: unset;
      padding: 0;
      max-width: unset;
      flex-grow: 1;
      margin-right: 7px;
    }

    ul {
      justify-content: space-between;
      max-width: unset;
      padding-right: 0;
    }

    .not-logged {
      text-align: right;
    }

    .not-logged a:first-child {
      margin-right: 12px;
    }

    .login-btn {
      margin-right: 0;
      line-height: unset;
      padding: 6px 8px 5px 8px;
    }
  }

  button.create-ad-btn {
    padding: 5px 8px 6px 4px;
    min-width: unset;
    margin-right: 0;
    background-color: var(--dorange) !important;
    color: white !important;

    .MuiButton-startIcon {
      margin: 0;
    }

    .MuiButton-startIcon path {
      fill: white;
    }

    .create-ad-btn-txt {
      // display: none;
      line-height: 0.8rem;
    }

    &:hover {
      background-color: var(--dblue) !important;
    }
  }

  nav.responsive-toolbar ul li {
    margin: 0;
    padding: 0;
  }

  .dropdown-container-active {
    top: 48px;
  }

  .image-logo {
    margin-left: 10px;
    margin-right: 0;
  }

  .menu-icon-right {
    margin: 19px 12px 19px 12px;
  }

  select.searchfield-regions-select {
    font-size: 0.8rem;
  }
}
