.login-container {
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-container-subwrapper {
    //width: 100%;
  }
}

.login-wrapper,
.register-wrapper {
  width: 100%;
  max-width: var(--avgwidth);
  margin: 0 auto;
  display: flex;
  height: 100%;
  min-height: 500px;
  background-color: var(--dblue);
  color: white;
  padding: 3rem;
  box-sizing: border-box;
}

.login-container-subwrapper {
  max-width: var(--avgwidth);
  width: 95vw;
}

.login-actions {
  width: 100%;
  max-width: var(--avgwidth);
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-end;
}

.login-chosen,
.login-choice,
.login-register {
  flex-grow: 1;
  text-align: center;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  letter-spacing: 0.8px;
  transition: 0.2s;
}

.login-choice-button-facebook {
  display: flex;
  margin-top: 1rem;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 40px;
  user-select: none;
  cursor: pointer;
}

.login-choice,
.login-register {
  color: #ffffff;
  background-color: var(--dorange);
}

.login-choice:hover,
.login-register:hover,
.login-choice-button-facebook:hover {
  background-color: white;
  color: #333;
}

.login-chosen {
  color: #ffffff;
  background-color: var(--dblue);
  font-weight: bold;
}

.login-form-container {
  padding-top: 40px;
}

.login-wrapper-choices,
.login-form-email {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
}

.login-choice-wrapper {
  display: flex;
}

.login-remember-me {
  justify-content: start !important;
  text-align: left;
}

.register-title,
.login-title {
  letter-spacing: 0.8px;
  font-size: 20px;
  padding: 30px 20px 20px 10px;
}

.register-input-containers {
  padding: 4px;
}

input.name[type="text"] {
  width: calc(50% - 1rem);
  display: inline-block;
  padding: 10px;
  margin: 1rem 1rem 0 0;
  border-radius: 4px;
  border: none;
}

input.register-input[type="radio"],
input.register-input[type="checkbox"] {
  margin-right: 4px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

input.mail-login-form[type="text"],
input.psw-login-form[type="password"] {
  width: 100%;
  min-width: 200px;
  padding: 10px;
  margin: 1rem 0 0 0;
  border-radius: 4px;
  border: none;
  color: #000;
  box-sizing: border-box;
}

input.mail[type="text"] {
  width: calc(100% - 1rem);
  min-width: 200px;
  padding: 10px;
  margin: 1rem 2rem 0 0;
  border-radius: 4px;
  border: none;
}

input.psw-login[type="password"] {
  width: 100%;
  min-width: 200px;
  padding: 10px;
  margin: 1rem 1rem 0 0;
  border-radius: 4px;
  border: none;
}

input.psw[type="password"],
input.psw[type="text"] {
  width: calc(50% - 2.5rem);
  min-width: 100px;
  display: inline-block;
  padding: 10px;
  margin: 1rem 1rem 0 0;
  color: #000;
  border-radius: 4px;
  border: none;
}

.register-psw-warning {
  font-size: 12px;
  color: red;
  margin: 0 0 10px 3px;
  font-style: italic;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder {
  color: #505050;
}

.eye-icon-psw {
  vertical-align: middle;
  cursor: pointer;
}

.eye-icon-psw:hover,
.eye-icon-psw-active:hover {
  color: #606060;
}

.eye-icon-psw-active {
  color: #909090;
  cursor: pointer;
}

.register-information {
  color: white;
  font-size: 12px;
  float: left;
  padding-top: 10px;
  padding-bottom: 22px;
}

.register-content,
.register-address-content,
.register-company-content,
.login-content {
  width: 50%;
  padding-left: 20px;
  padding-right: 10px;
}

.register-images {
  width: 50%;
  display: flex;
}

.create-account-pin {
  margin: auto;
}

.company-account-text {
  padding-bottom: 80px;
}

.login-form-label {
  color: black;
  text-align: left;
  float: left;
}

.login-form-email-input,
.login-form-psw {
  background: transparent;
  border-radius: 2px;
  width: 100%;
}

.login-problems-container {
  width: 100%;
  margin: 4px;
  padding-bottom: 30px;
  color: #505050;
}

.login-remember-me-text {
  margin-left: 4px;
  font-size: 12px;
}

.login-remember-me {
  float: left;
  margin-left: 10px;
}

.login-form-remember-me {
  vertical-align: middle;
  cursor: pointer;
}

.login-forgot-password {
  float: right;
  text-align: right;
  justify-content: right;
  margin-right: 10px;
  font-size: 12px;
  border-bottom: 1px dotted white;
  cursor: pointer;
  color: white;
}

.login-forgot-password-a {
  color: white;
  border-bottom: 1px dotted white;
}

.login-button,
button[type="button"].register-next-button {
  padding-top: 10px;
  color: white;
  background-color: var(--dorange) !important;
  border-radius: 2;
  width: 100%;

  .MuiButton-label {
    letter-spacing: 0.8px;
    font-size: 16px;
  }

  &:hover {
    background-color: var(--lgrey) !important;
    color: #000;
  }
}

.login-button-link {
  text-decoration: none;
  color: white;
  width: 100%;
  height: 100%;
}

.login-choice-container-google {
  width: 47%;
  min-width: 200px;
  padding-top: 10px;
  padding-right: 5px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}

.login-facebook-logo,
.login-google-logo {
  margin: 0 5px;
  vertical-align: bottom;
}

.login-choice-button-email {
  display: inline-block;
  border-radius: 1px;
  text-decoration: none;
  justify-content: space-between;
  width: 100%;
  transition: background-color 0.218s;
}

.login-choice-button-google {
  background-color: white;
  display: inline-block;
  border-radius: 1px;
  text-decoration: none;
  justify-content: space-between;
  width: 100%;
  transition: background-color 0.218s;
}

.login-choice-button-facebook {
  background-color: #2a77f2;
  color: white;
}

.login-choice-text-google {
  padding: 10px 5px 10px 5px;
  font-style: normal;
  color: #504040;
  font-size: 12px;
  font-style: bold;
}

.login-choice-text-facebook {
  display: inline;
}

.login-between-text {
  font-size: 12px;
  font-style: normal;
  padding: 10px;
  width: 100%;
  color: #504040;
}

input.forgotpassword-mail[type="text"] {
  border-radius: 3px;
  margin-top: 2rem;
  width: 50%;
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid var(--dgrey);
}

.forgotpassword-title {
  color: white;
}

@media screen and (max-width: 759px) {
  .register-images {
    display: none;
  }

  .register-content,
  .register-address-content,
  .register-company-content,
  .login-content {
    width: 100%;
    padding-left: 10px;
  }

  .login-choice,
  .login-chosen {
    padding: 10px 20px 10px 20px;
    height: 30px;
  }

  .login-choice-wrapper {
    display: unset;
  }

  .login-form-container {
    padding-bottom: 15px;
  }

  .login-chosen,
  .login-choice,
  .login-register,
  .login-choice-button-facebook {
    font-size: 12px;
  }

  .login-wrapper {
    padding: 2rem 0;
    min-height: unset;

    input {
      box-sizing: border-box;
    }
  }

  .login-container {
    padding-bottom: 0;
  }
}

select.validated,
input.validated[type="text"],
input.validated[type="number"],
textarea.validated,
input.validated[type="password"] {
  color: #000;
}
