.commonquestions-container {
  padding-top: 80px;
  padding-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.commonquestions-wrapper {
  border: 1px solid rgb(182, 180, 180);
  border-radius: 2px;
  padding: 10px;
  padding-left: 20px;
  margin: 10px;
  background-color: white;
}

.commonquestions-title {
  font-size: 1.3rem;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 0;
}

.commonquestions-content-container {
  padding: 10px;
}

.commonquestions-link {
  list-style: inside;
  padding: 4px;
  width: fit-content;
  margin-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
}

.commonquestions-link:hover {
  background-color: #eee;
}

.commonquestions-block-title {
  font-size: 1rem;
  color: rgb(233, 85, 39);
  padding-top: 10px;
}

.commonquestions-block-link {
  border: 1px solid rgb(194, 194, 194);
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  max-width: 500px;
  margin: 10px;
  cursor: pointer;
}

.commonquestions-block-link:hover {
  border: 1px solid rgb(52, 64, 86);
  color: rgb(52, 64, 86);
}

.commonquestions-question-title {
  font-size: 0.92rem;
  font-weight: bold;
  color: #373737;
}

.commonquestions-answer-active {
  padding-top: 10px;
  color: #373737;
  height: auto;
  opacity: 1;
  transition: height 0ms 1400ms, opacity 1600ms 0ms;
}

.commonquestions-answer {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 1400ms, opacity 400ms 0ms;
}
