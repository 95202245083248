.flex-mother{
    display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  width:100%;
  max-width: 1700px;
  height:fit-content;
}

.flex-mother-child-img{
  justify-content: center;
  position: relative;
}

.flex-mother-child-description{
    padding-top:25px;
    width: 600px;
    display: flex;
    flex-direction: column;
}

.flex-mother-child-text{
    margin-top:40px;
    margin-left:50px;
    flex-grow: 1;
    max-width: 1200px;
    width:100%;
}

@media screen and (max-width: 920px) {
  .flex-mother-child-description{
    margin:0;
    gap: 0;
    width: 100%;
  }

  .flex-mother-child-text{
    margin-top:20px;
    margin-left:0;
  }

  .my-page-wrapper{
    padding:10px;
  }

  .flex-mother-child-img{
    margin:0;
  }
}