.adtips {
  margin-top: 0px;
  margin-bottom: 30px;
}

.adtips-container {
  padding-top: 80px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.adtips-title-container {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.adtips-content {
  display: flex;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(223, 221, 221);
  height: auto;
  padding: 10px;
}

.adtips-wrapper {
  padding: 10px;
  margin: 0 auto;
}

.adtips-image-container {
  width: 50%;
}

ul.adtips-block-list {
  list-style: inside;
  padding: 10px;
}

.adtips-block {
  padding: 10px;
}

.adtips-block-title {
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 0 5px 0;
}

.adtips-image {
  width: 500px;
  height: auto;
  padding: 10px;
  border-radius: 3px;
}

.adtips-button-container {
  padding: 10px;
}
