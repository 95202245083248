.aboutus-container {
  padding-top: 80px;
  padding-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  letter-spacing: 0.7px;
  line-height: 20px;
}

.aboutus-wrapper {
  border: 1px solid rgb(157, 156, 156);
  border-radius: 2px;
  padding: 10px;
  margin: 10px;
  background-color: white;
}

.aboutus-title {
  font-size: 1.2rem;
}

.aboutus-content-container {
  padding: 10px;
}

.aboutus-link {
  list-style: inside;
}
