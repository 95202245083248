.pagination-container {
  max-width: var(--avgwidth);
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.pagination-before-button {
  float: left;
}

.pagination-before-button,
.pagination-next-button {
  display: flex;
  border: 1px solid var(--bordergrey);
  background-color: #FFF;
  padding: 3px;
  cursor: pointer;
}

.pagination-before-button:hover,
.pagination-next-button:hover {
  background-color: var(--bordergrey);
}

.pagination-next-button {
  float: right;
}

.pagination-wrapper {
  display: inline-flex;
  background-color: #FFF;
  border: 1px solid var(--bordergrey);
}

.pagination-block {
  display: flex;
  padding: 5px 14px 6px 14px;
  cursor: pointer;
  box-sizing: border-box;

  &.firstPage {
    border-right: 1px solid var(--bordergrey);
  }

  &.lastPage {
    border-left: 1px solid var(--bordergrey);
  }
}

.pagination-block-active {
  display: flex;
  padding: 5px 15px 5px 15px;
  color: white;
  background-color: rgb(234, 85, 37);
  cursor: pointer;
}

.pagination-block:hover {
  background-color: rgb(221, 218, 218);
}
