.contactus-container {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 10px;
  width: 100%;
  max-width: var(--avgwidth) !important;
  margin: 0 auto;
  line-height: 20px;
}

.contactus-content-info {
  font-style: italic;
  padding: 0 0 10px 0;
}

.contactus-wrapper {
  border: 1px solid rgb(157, 156, 156);
  border-radius: 2px;
  padding: 10px;
  margin: 3 1rem;
  background-color: white;
}

.contactus-title {
  font-size: 1.2rem;
  padding-left: 5px;
}

.contactus-content-container {
  padding: 10px;

  form {
    display: flex;
    flex-direction: column;
  
    button {
      width: 100%;
      max-width: 516px;
    }
  }
}

.contactus-options {
  cursor: pointer;
  border-radius: 2px;
}

select.contactus-options {
  border: 1px solid grey;
  padding-left: 5px;
  height: 35px;
  border-radius: 3px;
  margin-bottom: 10px;
}

input.contactus-title[type="text"],
input.contactus-email[type="text"],
input.contactus-phone[type="text"],
input.contactus-name[type="text"] {
  border: 1px solid grey;
  color: black;
  height: 30px;
  width: 100%;
  max-width: 500px;
  padding-left: 5px;
  margin-bottom: 10px;
  border-radius: 3px;
}

textarea.contactus-message {
  border: 1px solid grey;
  width: 100%;
  max-width: 500px;
  padding-left: 5px;
  border-radius: 3px;
  height: 100px;
}

input.contactus-title[type="text"]::placeholder,
input.contactus-mail[type="text"]::placeholder,
textarea.contactus-message::placeholder {
  color: rgb(46, 46, 46);
}

.contactus-button {
  padding: 3px;
  border-radius: 3px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  border: 1px solid rgb(237, 75, 26);
  margin-top: 10px;
  color: white;
  background-color: rgb(234, 85, 38);
}

.contactus-button:hover {
  background-color: rgb(237, 75, 26);
}

.contactus-required {
  border-right: 5px solid #344155 !important;
}
