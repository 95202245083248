.saved {
  padding: 100px 0 40px 0;
  color: #505050;
}

.saved-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid rgb(232, 232, 232);
  padding: 10px;
}

.saved-wrapper {
  width: calc(100% - 400px);
  min-width: 300px;
  border-left: 1px solid rgb(165, 164, 164);
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  text-align: center;
}

.saved-title-container {
  width: 100%;
  padding-top: 20px;
}

.saved-title {
  font-size: 1.6rem;
  letter-spacing: 0.85px;
}

.saved-subhead-container {
  padding: 1rem;
  font-style: italic;
}

.saved-content {
  border: 1px solid grey;
  min-height: 400px;
}
