.notfound {
  margin-bottom: 30px;
}

.notfound-container {
  padding-top: 80px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
}

.notfound-title-container {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.notfound-content {
  display: flex;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(223, 221, 221);
  height: auto;
  padding: 10px;
}

.notfound-wrapper {
  padding: 10px;
  margin: 0 auto;
}

.notfound-image-container {
  width: 100%;
  display: flex;
}

.notfound-image {
  width: 100% !important;
  height: auto;
  box-sizing: border-box;
  max-width: 500px;
  margin: 0 auto;
}

ul.notfound-block-list {
  list-style: inside;
  padding: 10px;
}

.notfound-block {
  padding: 10px;
}

.notfound-block-title {
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 0 5px 0;
}

.notfound-button-container {
  padding: 0;
  display: flex;
  justify-content: center;
}

.notfound-button {
  background-color: var(--dorange);
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  margin-top: 2rem;
  border-radius: 5px;

  &:hover {
    background-color: var(--dblue);
  }
}