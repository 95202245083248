.termsofuse-container {
  padding-top: 80px;
  padding-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  
  @media( max-width: 768px ) {
    font-size: 0.8rem;
  }
}

.termsofuse-wrapper {
  border: 1px solid rgb(182, 180, 180);
  border-radius: 2px;
  padding: 10px;
  padding-left: 20px;
  margin: 10px;
  background-color: white;
}

.termsofuse-title {
  font-size: 1.2rem;

  @media( max-width: 768px ) {
    font-size: 0.8rem;
  }
}

.termsofuse-content-container {
  padding: 10px;
}

.termsofuse-link {
  list-style: inside;
  padding: 4px;
}

.termsofuse-content-link {
  list-style: inside;
  padding: 4px;
  width: fit-content;
  margin-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.termsofuse-content-link:hover {
  background-color: #eee;
}

.termsofuse-link-link {
  list-style: inside;
  padding-left: 20px;
}

.termsofuse-a-href {
  color: blue;
}

.termsofuse-block-title {
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.termsofuse-general-container li {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tosTable {
  max-width: 800px;
  font-size: 0.8rem;

  @media( max-width: 768px ) {
    font-size: 0.4rem;
    font-family: small;
  }

  td {
    width: 25%;
    padding: 5px;
  }

  tr:nth-of-type(odd) {
    background-color: rgba( 0,0,0,0.2 );
  }
}
