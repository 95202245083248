@import "./pages/home.scss";
@import "./pages/createad.scss";
@import "./pages/messages.scss";
@import "./pages/login.scss";
@import "./pages/mypage.scss";
@import "./pages/adtips.scss";
@import "./pages/settings.scss";
@import "./pages/saved.scss";
@import "./pages/searchhistory.scss";
@import "./pages/nogloggedin.scss";
@import "./pages/chosenad.scss";
@import "./pages/userprofile.scss";
@import "./pages/advertising.scss";
@import "./pages/aboutus.scss";
@import "./pages/contactus.scss";
@import "./pages/termsofuse.scss";
@import "./pages/shopsafe.scss";
@import "./pages/cookies.scss";
@import "./pages/commonquestions.scss";
@import "./pages/resetpassword.scss";

@import "./components/toolbar.scss";
@import "./components/footer.scss";
@import "./components/categoriesicons.scss";
@import "./components/notfound.scss";
@import "./components/dialog.scss";
@import "./components/pagination.scss";

@import "./pages/yrkesguide.scss";

:root {
  width:100%;
  // overflow-x: hidden;
  --orange: #ea5526;
  --dorange: #f9712d;
  --pink: #de4057;
  --lblue: #1d6882;
  --dblue: #334156;
  --lgrey: rgb(251, 248, 247);
  --dgrey: #858383;
  --mgrey: #dbd9d9;
  --bggrey: #fafafa;
  --bordergrey: #e7e7e7;
  --maxwidth: 1680px;
  --avgwidth: 1200px;
  --brickimgheight: 250px;
  --banner-side-width: 160px;
  --banner-top-height: 250px;

  @media( max-width: 768px ) {
    --maxwidth: 100vw;
  }
}

* {
  font-family: "Montserrat", sans-serif;
  outline: none;
}

html {
  scroll-behavior: smooth;
}

.mainwrapper {
  min-height: calc( 100vh - 7rem - 60px );
  padding: 1rem;
  max-width: var(--maxwidth);
  margin: 0 auto;
  box-sizing: border-box;

  @media(max-width: 768px) {
    padding: 1.2rem 0 0 0;
    min-height: calc( 100vh - 215px - 100px );
  }
}

.mainwrapper-margin {
  margin-top: 105px;
  min-height: 95vh;
}

.App {
  display: grid;
  min-height: 100vh;
  position: relative;
  grid-template-rows: 60px auto auto;
  background-color: var(--bggrey);
}

footer {
  bottom: 0;
  width: 100%;
}

div[adunit="112"],
div[adunit="116"],
div[adunit="212"] {
  display: none;
}

div[adunit="101"],
div[adunit="102"],
div[adunit="103"],
div[adunit="104"],
div[adunit="201"],
div[adunit="202"] {
  display: block;
}

div[adunit="103"],
div[adunit="104"] {
  width: var(--banner-side-width);
  height: 600px;
}

div[adunit="105"] {
  display: none;
}

@media screen and (max-width: 758px) {
  .App {
    grid-template-rows: 80px auto auto;
  }

  .mainwrapper {
    margin: 0;
  }

  .mainwrapper-margin {
    margin-top: 310px;
  }

  img.home-banner-first-wrapper {
    max-width: 100vw;
  }

  div[adunit="112"],
  div[adunit="116"],
  div[adunit="212"] {
    display: block;
  }

  div[adunit="101"],
  div[adunit="102"],
  div[adunit="103"],
  div[adunit="104"],
  div[adunit="201"],
  div[adunit="202"] {
    display: none;
  }
}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 10px;
  margin-inline-end: 0;
}

body {
  margin: 0;
  position: relative !important;
  overflow: auto !important;
  background-color: var(--lgrey);
}

p {
  margin: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

figure.image-logo img {
  height: 27px;
  max-width: unset;
}

@media ( max-width: 768px ) {
  .shopsafe-general-container ul,
  .cookies-general-container ul,
  .shopsafe-content-container ul {
    display: block;
    list-style: disc;
    padding-left: 0;

    li {
      list-style: disc;
      word-break: break-word;
    }
  }

  .termsofuse-content-container ul {
    display: block;
    padding-left: 0;

    li {
      list-style: none;
      word-break: break-word;
    }
  }

  .commonquestions-content-container ul,
  .aboutus-content-container ul {
    display: block;
    padding-left: 0;

    .commonquestions-block-link {
      width: unset;
      max-width: unset;
    }
  }
}