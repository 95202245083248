.resetpassword-container {
  padding-top: 90px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding-bottom: 30px;
}

input.resetpassword-new[type="password"] {
  border: 1px solid rgb(203, 203, 203);
  border-radius: 3px;
  width: 100%;
  max-width: 350px;
}
