.userprofile {
  padding-top: 60px;
  width: 100%;
  max-width: var(--avgwidth) !important;
  margin: 0 auto;
  height: 100%;
  min-height: fit-content;
}

.userprofile-container {
  padding: 10px;
  margin: 10px;
  border: 1px solid rgb(209, 209, 213);
  background-color: white;
}

.userprofile-wrapper {
  display: flex;
}

.userprofile-user {
  display: flex;
  height: 100px;
  align-items: center;
  padding: 15px;
  margin: 10px;
  width: calc(100% - 100px);
}

.userprofile-user-name {
  vertical-align: middle;
  margin-left: 10px;
  font-size: 1.3rem;
  letter-spacing: 0.8px;
}

.userprofile-no-ads {
  font-size: 1rem;
  text-align: center;
  padding: 10px;
}

.userprofile-user-image {
  width: 100px;
  height: 100px;
  border-radius: 3px;
  object-fit: cover;
}

.userprofile-user-city {
  font-size: 0.75rem;
  padding-top: 5px;
}

.userprofile-ads-text {
  font-size: 1.2rem;
  padding: 10px 10px 10px 15px;
}

.userprofile-ad {
  display: flex;
  border: 1px solid rgb(180, 178, 178);
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.userprofile-ad:hover {
  background-color: #eee;
  cursor: pointer;
}

.userprofile-ad-info {
  width: calc(100% - 10px);
}

.userprofile-ad-title {
  font-size: 1rem;
  letter-spacing: 0.3px;
  padding: 8px 5px 8px 5px;
}

.userprofile-ad-price {
  font-size: 0.9rem;
  padding: 5px;
  font-weight: bold;
  color: rgb(224, 85, 43);
}

.userprofile-ad-date {
  font-size: 0.85rem;
  padding: 5px;
  color: rgb(95, 95, 95);
}

.userprofile-ad-image {
  width: 200px;
  height: 100px;
  padding: 0 10px 0 10px;
  object-fit: cover;
}

.userprofile-ad-link {
  width: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  float: right;
  font-size: 0.85rem;
  color: rgb(95, 95, 95);
}

.userprofile-favorite {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

.userprofile-favorite:hover {
  color: red;
}

.userprofile-favorite-active {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

@media screen and (max-width: 380px) {
  .userprofile-ad {
    display: grid;
  }

  .userprofile-ad-image-container {
    display: flex;
    margin: auto;
  }

  .userprofile-ad-info {
    text-align: center;
  }

  .userprofile-ad {
    height: 100%;
    width: fit-content;
    max-width: 170px;
    margin-bottom: 10px;
  }

  .userprofile-ads {
    height: 100%;
    width: fit-content;
    max-width: 170px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  .userprofile-ad {
    height: 100%;
  }

  .userprofile-favorite {
    padding: 0;
    margin: 0;
    justify-content: flex-end;
  }

  .userprofile-ad-link {
    display: none;
  }

  .userprofile-ad-info {
    width: 100%;
    padding: 0;
  }

  .userprofile-ad-title {
    padding: 2px 0 2px 5px;
  }

  .userprofile-ad-price {
    padding: 2px 0 2px 5px;
  }

  .userprofile-ad-date {
    padding: 2px 0 2px 5px;
  }

  .userprofile-ad-image {
    width: 150px;
    height: 100px;
  }
}
