.member-page-main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.member-page-main-title-container {
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.member-page-main-title {
  padding: 10px;
  text-align: center;
}

.member-page-top-info,
.member-page-bottom-info {
  display: flex;
  background-color: #FFF;
  border: 1px solid var(--bordergrey);
  box-sizing: border-box;
  justify-content: space-between;
  margin: 1.5rem 0;
  padding: 10px;
  align-items: center;

  p {
    padding: 0;
  }
}
span {
  height: fit-content;
}

.my-page-container {
  margin: 2rem auto !important;
  max-width: var(--avgwidth) !important;
  width: 100%;

  @media( max-width: 768px ) {
    margin: 0 auto !important;
  }
}

.my-page-wrapper {
  display: grid;
  grid-template-columns: 300px auto;
  width: 100%;
  box-sizing: border-box;

  @media( max-width: 768px ) {
    grid-template-columns: 100%;
  }
}

.my-page-wrapper-profile {
  justify-content: center;
  padding: 10px 10px;
  background-color: var(--dblue);
  color: white;
  height: fit-content;
}

.my-wrapper-profile-icon {
  padding-top: 10px;
  font-size: 200px;
}

.my-page-figure {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 10px;
}

.my-page-profile {
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto;
  border-radius: 50%;
}

.my-page-wrapper-profile-name {
  font-size: 17px;
  letter-spacing: 0.8px;
  padding-left: 10px;
  text-align: center;
}

.my-page-wrapper-profile-mail {
  font-size: 14px;
  letter-spacing: 0.8px;
  padding-left: 10px;
  text-align: center;
}

.my-page-wrapper-profile-phone {
  font-size: 14px;
  letter-spacing: 0.8px;
  padding-left: 10px;
  text-align: center;
}

.my-page-wrapper-block {
  margin: 4px;
  display: flex;
  align-items: center;
  height: 30px;
  background-color: white;
  color: var(--dblue);
  cursor: pointer;
  border-radius: 2px;
  transition: 0.2s;
}

.my-page-wrapper-block:hover {
  background-color: var(--dorange);
  color: white;
}

.my-page-links {
  display: block;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.my-page-links-inline {
  display: flex;
  overflow-x: scroll;
}

.my-page-wrapper-block-icon {
  vertical-align: middle;
  margin: 0 10px 0 10px;
}

.my-page-wrapper-block-text-link {
  color: white;
  text-decoration: none;
}

.my-page-wrapper-block-text {
  font-size: 0.7rem;
}

.my-page-wrapper-ads {
}

.my-page-wrapper-old-ads {
  padding-bottom: 20px;
}

.my-page-actions-container {
  color: #797878;
  display: flex;
  margin-top:10px;
  justify-content: space-between;
}

.my-page-action-container {
  background-color: rgb(213, 211, 211);
  padding: 3px;
  margin: 5px;
  justify-content: flex-end;
  border-radius: 3px;
  display: flex;
  line-height: 1.5rem;

  .treddyboll {
    width: 1rem;
    height: 1rem;
    margin: 4px;
  }
}

.my-page-action-container:hover {
  background-color: rgb(186, 186, 186);
  padding: 3px;
  margin: 5px;
  justify-content: flex-end;
  border-radius: 3px;
}

.my-page-ads,
.my-page-old-ads {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 1rem 1rem;
}

.my-page-ads-title {
  margin: 10px;
  font-size: 22px;
  letter-spacing: 0.85px;
}

.my-page-ads-title-container {
  width: 100%;
  padding: 30px;
}

.my-page-ads-wrapper {
  padding: 0 0 0 3rem;
  display: flex;
  flex-direction: column;
}

.my-page-wrapper-ads-blocks {
  display: flex;
  width: 100%;
}

.my-page-wrapper-ads-block {
  float: left;
  cursor: pointer;
}

.my-page-wrapper-ads-block-wrapper {
  background-color: white;
  padding: 0.5rem;
  border: 1px solid var(--bordergrey);
  margin-bottom: 20px;
}


.my-page-wrapper-ads-blocks-info-wrapper {
  max-width: 900px;
  margin: 0 auto;
  height: 40px;
  border-radius: 3px;
}

.my-page-wrapper-ads-blocks-info {
  width: calc(100% - 20px);
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid lightgray;
  height: 40px;
  border-radius: 3px;
  box-shadow: rgba(60, 60, 60, 0.25) 0px 2px 2px 1px;
  letter-spacing: 1px;
}

.my-page-wrapper-ads-block-info-amount {
  font-size: 15.5px;
  padding: 10px 10px 5px 10px;
  margin-right: 10px;
  float: right;
}

.my-page-wrapper-ads-block-info-active,
.my-page-old-ads-text {
  font-size: 15.5px;
  padding: 10px 10px 5px 10px;
  margin-left: 10px;
  float: left;
}

.my-page-ads-image {
  width: 100%;
  height: auto;
  min-height: 150px;
  overflow: hidden;
  object-fit: cover;
  max-height: 130px;
}

.my-page-wrapper-ads-block-info {
  text-align: left !important;
  font-size: 14px;
  width: 100%;
}

.my-page-block-info-title {
  padding: 0.5rem 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.85px;
}

.my-page-block-info-price {
  font-size: 1rem;
}

.my-page-block-info-created {
  font-size: 0.8rem;
}

.mypage-banner-container {
  padding-top: 100px;
  padding-bottom: 40px;
  width: 100%;
  max-width: fit-content;
  margin: 0 auto;
}

.my-page-actions-container {
  text-align: right;
}

.mypage-banner-first {
  width: 100%;
}

@media screen and (max-width: 920px) {
  .my-page-wrapper-old-ads{
    padding:0;
  }

  .member-page-top-info,
  .member-page-bottom-info {
    padding: 0.5rem 1rem;
    margin: 0;
    width: 95vw;
    align-items: center;
    height: unset;
    font-size: 0.9rem;
 
    p{
     padding: 0;
    }
  }

  .my-page-ads, .my-page-old-ads{
    margin: 1rem 0;
    align-items: center;
    grid-template-columns: 50% 50%;
    gap: 0.5rem 0.5rem;
  }

  .member-page-main-title{
    font-size:25px;
    padding:0;
  }

  .my-page-wrapper-profile{
    padding:0;
  }

  .my-page-wrapper-ads-block{
    width:100%;
    margin:0;
  }
  .my-page-wrapper-ads-block-wrapper{
    margin:0;
  }

  .my-page-container {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
  .my-page-wrapper {
    margin: 0px;
    max-height: fit-content;
    min-height: 20vh;
  }

  .my-page-wrapper-profile {
    display: none;
  }

  .my-page-wrapper-ads {
    border-left: none;
  }

  .my-page-wrapper-active-ads {
    padding-left: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .my-page-wrapper-profile {
    display: none;
  }
}


@media screen and (max-width: 40rem ) {
  .my-page-ads-wrapper {
    padding: 0;
    width: 95vw;
  }
}