.notloggedin {
  margin-bottom: 30px;
}

.notloggedin-container {
  padding-top: 100px;
  padding-bottom: 50px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.notloggedin-title-container {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.notloggedin-content {
  display: flex;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(223, 221, 221);
  height: auto;
  padding: 10px;
}

.notloggedin-wrapper {
  padding: 10px;
  margin: 0 auto;
}

ul.adtips-block-list {
  list-style: inside;
  padding: 10px;
}

.notloggedin-block {
  padding: 10px;
}

.notloggedin-block-title {
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 0 5px 0;
}

.notloggedin-button-container {
  padding: 10px;
  display: table;
  margin: 0 auto;
}

button[type="button"].registerlogin {
  background-color: var(--dorange);
  color: white;
  padding: 10px 20px;

  &:hover {
    background-color: var(--dblue);
  }
}
