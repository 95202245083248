.yrkesguide-wrapper {
  display: flex;
  flex-direction: column;
}

.yrkesguide-container {
  display: flex;
  // width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
}

.yrkesguide-container.yrkesguide-hero {
  background-color: var(--dblue);
  color: #FFF;
  
  img {
    max-width: 200px;
  }

  .ingress {
    max-width: var(--avgwidth);
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
}

.yrkesguide-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--avgwidth);
}

.yrkesguide-subheading {
  margin-top: 2rem;
  font-size: 1.2rem;
  text-align: right;
  color: var(--dorange);

  a {
    color: var(--dblue);
  }
}

.yrkesguide-ads {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 2rem;
  justify-content: center;

}

.yrkesguide-ad {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--dorange);
  border-bottom: 10px solid var(--dorange);
  box-shadow: 5px 5px 5px rgba( 0,0,0,0.4);
  padding: 5px;
  background-color: white;

  img {
    width: 100%;
  }

  .yrkesguide-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    line-height: 2;
    letter-spacing: 1px;

    .phone {
      line-height: 1.5rem;
    }

    a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #000;
      text-decoration: none;
    }

    a.yrkesguide-link {
      font-size: 1.1rem;
      font-weight: bold;
      color: var(--dorange);
      margin-top: 1rem;
      border-top: 1px solid var(--dorange);
      border-bottom: 1px solid var(--dorange);
      padding: 0.5rem 0.5rem 0.4rem 0.5rem ;
      text-transform: uppercase;
      transition: 0.2s;

      &:hover {
        background-color: var(--dorange);
        color: #FFF;
      }
    }
  }
}

.yrkesguide-description {
  line-height: 1.5rem;
}

@media( max-width: 40rem ) {
  .yrkesguide-container {
    width: unset;
  }
  
  .yrkesguide-ads {
    grid-template-columns: 90%;
    grid-gap: 2rem 0;
  }
}

.yrkesguider-lista a {
  display: inline-block;
  width: 33%;
  text-align: center;
  padding: 0.5rem 0;
  color: var(--dblue);
  text-decoration: none;

  @media( max-width: 40rem ) {
    width: 100%;
  }
}