.cookies-container {
  padding-top: 80px;
  padding-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.cookies-wrapper {
  border: 1px solid rgb(182, 180, 180);
  border-radius: 2px;
  padding: 10px;
  padding-left: 20px;
  margin: 10px;
  background-color: white;
}

.cookies-title {
  font-size: 1.2rem;
}

.cookies-content-container {
  padding: 10px;
}

.cookies-link {
  list-style: inside;
  padding: 4px;
}

.cookies-block-title {
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
