.settings {
  padding: 100px 0 40px 0;
  background-color: whitesmoke;
  color: #505050;
}

.settings-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 1rem;
    width: 50vw;

  h4 {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    text-align: left;
    margin-top: 1rem;
  }

  small {
    display: block;
    text-align: left;
  }
}

.settings-wrapper {
  width: calc(100% - 400px);
  min-width: 300px;
  border-left: 1px solid rgb(165, 164, 164);
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  text-align: center;
}

.settings-title-container {
  width: 100%;
  max-width: 500px;
  height:20px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
}

.settings-title {
  margin: 10px;
  font-size: 22px;
  letter-spacing: 0.85px;
}

.settings-subhead-container {
  padding: 10px;
  font-style: italic;
  width: 100%;
  max-width: 270px;
  margin: 0 auto;
}

.settings-content {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left:200px;
}

.settings-name-container,
.settings-info-container {
  width: 100%;
  padding: 10px 10px 10px 0px;
}

.settings-fname-container {
  width: 50%;
  padding-right: 5px;
}

.settings-lname-container {
  width: 50%;
  padding-left: 5px;
}

input.settings-fname[type="text"],
input.settings-lname[type="text"] {
  width: 100%;
  border: 1px solid grey;
  padding: 10px;
  border-right: 5px solid rgb(52, 65, 85);
}

.settings-phone-container {
  width: 50%;
  margin-top:10px;
}

input.settings-phone[type="text"] {
  width: 100%;
  border: 1px solid grey;
  // border-right: 5px solid #344155;
  padding: 10px;
}

.settings-mail-container {
  width: 50%;
  padding-right: 5px;
}

input.settings-mail[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid grey;
  border-right: 5px solid rgb(52, 65, 85);
}

.settings-avatar-container {
  width: 100%;
  position: relative;
  max-width: 100px;
  padding: 10px;
}

.settings-avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid rgb(215, 218, 223);
  cursor: pointer;
}

.settings-avatar:hover {
  border: 1px solid grey;
}

.settings-remove-avatar-button {
  position: absolute;
  top: 9px;
  right: -39px;
  cursor: pointer;
}

.settings-remove-avatar-button:hover {
  color: red;
}

.settings-zipcode-container {
  width: 100%;
  padding: 10px 10px 10px 0px;
  text-align: left;
}

input.settings-zipcode[type="number"] {
  padding: 10px;
  border: 1px solid grey;
  color: #505050;
  // border-right: 5px solid rgb(52, 65, 85);
}

input.settings-zipcode[type="number"]::placeholder {
  color: #505050;
}

.settings-city-container {
  width: 100%;
  padding: 10px 10px 10px 0px;
  text-align: left;
}

select.settings-city {
  width: 50%;
  padding-left: 5px;
  border: 1px solid grey;
  padding: 5px;
}

label.settings-messages,
label.settings-newsletter,
label.settings-consent {
  vertical-align: top;
  padding-left: 5px;
  cursor: pointer;
}

.settings-password-container {
  width: 100%;
  padding: 10px 10px 10px 0px;
  text-align: left;
}

input.settings-password[type="password"] {
  padding: 10px;
  border: 1px solid grey;
  border-right: 5px solid rgb(52, 65, 85);
}

.settings-subscription-container {
  width: 100%;
  margin-top: 2rem;
  text-align: left;
}

.settings-buttons-container {
  padding-top: 40px;
  width:55%;
}

.settings-save-container {
  float: left;
}

.settings-delete-container {
  float: right;
}

.settings-buttons {
  cursor: pointer;
  margin:0;
}

@media screen and (max-width: 920px) {
  .settings-wrapper {
    border: none;
    width: 100%;
  }

  .settings-title-container{
    max-width: 1000px;
  }

  .settings-buttons-container{
    width:100%;
  }

  .my-page-container{
    margin:0;
  }

  .settings-container h4{
    text-align: center;
  }

  .settings-content {
    margin: 0 0 0 0;
    width:95vw;
    flex-wrap:wrap;
  }

  .settings-container{
    width:95vw;
    margin: 0;
  }

  .settings-name-container, .settings-info-container{
    width:95vw;
    padding: 0 0 0 0;
  }

  .settings-fname-container{
    width:95%;
  }

  .settings-mail-container{
    width:95%;
  }

  .settings-phone-container{
    width:95%;
  }

  .settings-avatar-container{
    max-width: none;
    padding:0 0 0 0;
  }

  .settings-zipcode-container{
    width:95%;
  }

  input.settings-zipcode[type="number"] {
    width:95%;
  }

  select.settings-city{
    width:99%;
  }
  .settings-city-container{
    text-align: none;
    width:99%;
  }

  .settings-password-container{
    width:95%;
  }

  input.settings-password{
    width:95%;
  }
}
