.searchword {
  padding: 100px 0 40px 0;
  color: #505050;
}

.searchword-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid rgb(232, 232, 232);
  padding: 20px;
}

.searchword-wrapper {
  width: calc(100% - 400px);
  min-width: 300px;
  border-left: 1px solid rgb(165, 164, 164);
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  text-align: center;
}

.searchword-title-container {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.searchword-title {
  font-size: 1.6rem;
  letter-spacing: 0.85px;
}

.searchword-subhead-container {
  padding: 1rem;
  font-style: italic;
}

.searchword-content {
  border: 1px solid grey;
  min-height: 400px;
}

.searchword-content-container {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  padding: 10px;
  color: #505050;
}

.searchword-links:hover {
  background-color: #eee;
}

@media screen and (max-width: 758px) {
  .searchword-wrapper {
    border: none;
    width: 100%;
  }
}
