.shopsafe-container {
  padding-top: 80px;
  padding-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.shopsafe-wrapper {
  border: 1px solid rgb(182, 180, 180);
  border-radius: 2px;
  padding: 10px;
  padding-left: 20px;
  margin: 10px;
  background-color: white;
}

.shopsafe-title {
  font-size: 1.2rem;
}

.shopsafe-content-container {
  padding: 10px;
}

.shopsafe-content-link {
  list-style: inside;
  padding: 4px;
  width: fit-content;
  margin-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.shopsafe-content-link:hover {
  background-color: #eee;
}

.shopsafe-link-link {
  list-style: inside;
  padding-left: 20px;
}

.shopsafe-a-href {
  color: blue;
}

.shopsafe-block-title {
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
