.conversation-svante {
  display: flex;
  cursor: pointer;
  margin: 1rem 0;

  .conversationDataWrapper {
    flex-grow: 1;
  }
}

.conversation-svante:hover {
  background-color: rgb(230, 228, 228);
}

.conversationImg-svante {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.conversationName-svante {
  font-weight: 600;
  color: var(--lblue);
  flex-grow: 1;
}
.chatMenu {
  border-right: 1px solid var(--lblue);
}

.chatMenu ul{
  margin:0;
  padding: 0;
  display: flex;
  flex-direction: column;

  li {
    align-items: center;
    width: 100%;
  }
}

.chatBox-svante {
  flex-grow: 1;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chatBoxWrapper h5 {
  align-self: center;
}

.chatBoxTop-svante {
  height: 100%;
}

.chatBoxTop-header-information {
  background-color: lightblue;
  position: fixed;
  width: 100%;
}
.message {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.message.own {
  align-items: flex-end;
}

.messageTop {
  display: flex;
}

.messageBottom {
  font-size: 12px;
  margin-top: 10px;
}

.messenger {
  display: grid;
  grid-template-columns: 350px auto;
  height: 100%;
}

.chatBoxBottom {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 80% 20%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.chatMessageInput {
  width: 100%;
  height: 90px;
  padding: 10px;
  margin-left: 20px;
  resize:none;
  box-sizing: border-box;

  @media( max-width: 768px ) {
    margin-left: 0;
  }
}

.chatSubmitButton {
  width: 100%;
  height: 90px;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  background-color: var(--lblue);
  color: white;
  margin-right: 25px;
}

.chatMenuWrapper,
.chatOnlineWrapper {
  padding: 1rem 0;
}

.chatBoxWrapper {
  padding: 0;
}

.messages-container-content {
  min-width: 350px;
  padding-left: 4px;
  margin: 0 auto;
  margin-top: 20px;
}

.messages-container-content-inbox-wrapper {
  text-align: center;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.messages-container-content-inbox-active {
  padding: 10px 10px 0 10px;
  letter-spacing: 0.9px;
  background-color: rgb(224, 78, 54);
  color: white;
  cursor: pointer;
  border-bottom: 1px solid rgb(224, 78, 54);
  padding-bottom: 1px;
  height: 40px;
  border: 1px solid rgb(224, 78, 54);
  border-radius: 2px;
  width: 100%;
  max-width: 50%;
}

.messages-container-content-inbox {
  padding: 10px 10px 0 10px;
  color: #505050;
  border: 1px solid rgb(235, 232, 232);
  letter-spacing: 0.9px;
  border-radius: 2px;
  cursor: pointer;
  height: 40px;
  width: 100%;
  max-width: 50%;
}

.messages-container-content-inbox:hover {
  background-color: rgb(230, 225, 225);
}

.messages-container-wrapper {
  border: 1px solid red;
  width: 100%;
  max-width: 300px;
  margin: 30px 10px 0 10px;
}

.message-container-ads-links {
  list-style: none;
  text-decoration: none;
  background-color: #efefef;
  border-radius: 2px;
  margin: 0 10px 10px 10px;
  cursor: pointer;
  border: 1px solid white;
}

.message-container-ads-links:hover {
  border: 1px solid rgb(224, 78, 54);
}

.message-ad-image {
  width: 65px;
  height: 50px;
  margin: 5px;
  vertical-align: middle;
  object-fit: cover;
  border-radius: 2px;
  border: 1px solid rgb(145, 144, 144);
}

.message-ad-text {
  color: rgb(106, 105, 105);
  background-color: white;
  padding: 14px 0 10px 10px;
  width: calc(100% - 100px);
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.8px;
  text-overflow: ellipsis;
  display: flex;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  flex-direction: column;
}

.message-unread {
  display: flex;
  align-items: center;
  background-color: rgb(246, 88, 62);
}

.message-unread span {
  text-align: center;
  color: #fff;
  width: 1.6rem;
  font-size: 0.8rem;
}

.message-ad-text-active {
  color: #000;
  background-color: #eee;
  padding: 14px 0 10px 10px;
}

.message-ad-amount {
  display: flex;
  background-color: #aeaeae;
  width: 65px;
  height: 60px;
  font-size: 0.9rem;
  text-align: center;
  letter-spacing: 0.8px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(140, 139, 139);
}

.message-ad-amount-active,
.message-ad-amount:hover {
  display: flex;
  background-color: rgb(246, 88, 62);
  width: 65px;
  height: 60px;
  font-size: 0.9rem;
  text-align: center;
  letter-spacing: 0.8px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.messages-main-content {
  width: 100%;
  height: 100%;
  max-height: 900px;
  margin: 0 auto;
}

.messages-inbox {
  width: 30%;
  float: left;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25);
  height: 100%;
  min-height: 900px;
  max-height: 900px;
}

.messages-chatbox {
  float: left;
  height: 100%;
  width: 100%;
}

.chat {
  scrollbar-width: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.chat h5 {
  font-size: 1rem;
  font-style: italic;
}

.chat-wrapper {
  height: 100%;
  width: 100%;
}

.chat-conversation {
  height: 28rem;
  padding: 0 1rem;
  overflow-y: scroll;
}

.messages {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.messageText {
  padding: 10px 15px;
  border-radius: 20px;
  background-color: lightblue;
  color: black;
  max-width: 300px;
}

.message.own .messageText {
  background-color: rgb(211, 211, 211);
  color: black;
}

.messages-inbox-users {
  cursor: pointer;
}

.messages-inbox-user {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  list-style: none;
  padding: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
}

.messages-container-ads {
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.messages-container-ads-buyers-active {
  height: auto;
  opacity: 1;
  transition: height 0ms 1400ms, opacity 1600ms 0ms;
}

.messages-container-ads-buyers {
  overflow: hidden;
  height: 0;
  opacity: 0;
  border-radius: 5px;
  transition: height 0ms 1400ms, opacity 400ms 0ms;
}

.messages-container-ads-buyer {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-bottom: 1px solid #eee;
  list-style: none;
  padding: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
}

.messages-container-ads-buyer-name {
  margin-left: 10px;
}

.messages-container-ads-buyer:hover {
  background-color: #eee;
}

.message-container-ads-link {
  display: flex;
}

.messages-inbox-user-first {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  list-style: none;
  padding: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
  background-color: #eee;
}

.messages-inbox-user:hover {
  background-color: #eee;
}

.my-date {
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: 11px;
  font-style: italic;
  color: #404040;
  width: 150px;
  text-align: right;
}

.yours-date {
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 11px;
  font-style: italic;
  color: #404040;
  width: 150px;
}

.messages-container-conversation {
  width: 100%;
  background-color: #efefef;
  margin-bottom: 1rem;
  margin-right: 1rem;
  height: auto;
  min-height: 500px;
}

.messages-container-conversation-info {
  padding: 20px 0 15px 0;
  color: #808080;
  letter-spacing: 0.8px;
  font-size: 12px;
  text-align: center;
}

.messages-banner-first-wrapper {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
}

.messages-input-container {
  background-color: lightgray;
  display: flex;
  height: 60px;
  align-items: center;
}

.message-input-field {
  background-color: white;
  border: 1px solid rgb(160, 158, 158);
  width: calc(100% - 50px - 1rem);
  padding: 10px;
  border-radius: 3px;
  margin: 10px;
  height: 30px;
  margin-left: auto;
}

.message-send-button {
  display: flex;
  align-items: center;
  color: grey;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.chatBoxTop-header {
  display: none;
}

.message-send-button:hover {
  color: rgb(106, 106, 106);
}

.adListHeading {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9rem;
}

.adListName {
  padding-top: 5px;
  font-size: 12px;
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .messages-container-content {
    width: 100%;
    border: none;
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) {
  .messages-container-content {
    margin: 0 auto;
    margin-left: 0;
  }
  .messages-container-messages-conversation {
    display: flex;
  }
}

@media screen and (max-width: 819px) {
  .chatBox-svante{
    margin:0;
  }

  .chatMenu{
    margin:0;
  }

  .messenger {
    grid-template-columns: 100%;
  }
  .message {
    padding-top: 25px;
  }
  .chatMenu {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: white;
    border: none;
  }
  .chatBox-svante {
    padding-top: 10px;
    flex: none;
  }
  .chatBoxTop-svante {
    height: 100%;
  }

  .chat_header-wrapper {
    position: sticky;
    top: 0;
    width: 100%;
    height: 65px;
  }

  .chatBoxTop-header {
    display: block;
    color: var(--lblue);
    position: absolute;
    background-color: white;
    width: 100%;
  }

  .conversation-ulist-svante {
    flex-direction: column;
  }

  .chatSubmitButton {
    margin: 0;
  }

  .chatBoxWrapper h5 {
    text-align: center;
  }

}
