#sort {
  border: 0;
  color: inherit;
  cursor: pointer;
}

.home-container-head {
  text-align: center;
}

.home-container {
  width: 100%;
  max-width: 900px;
  height: auto;
  margin: 0 auto;
  padding: 10px 10px 0 10px;
}

.head-banner-first {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.head-banner {
  width: 100%;
  max-width: 900px;
  height: auto;
  margin: 0 auto;
  border-radius: 2px;
}

img {
  max-width: 95vw;
}

.home-banner-first-wrapper {
  width: 100%;
  justify-content: center;
  //max-width: var(--avgwidth);
  margin: 0 auto;
  //height: var(--banner-top-height);
  text-align: center;

  //@media( max-width: 768px ) {
    height: auto;
  //}

  .MuiSkeleton-root {
    margin: 0 auto;
  }
}

.home-banner-last-wrapper {
  width: 100%;
  justify-content: center;
  max-width: var(--avgwidth);
  margin: 0 auto;
  padding-top: 10px;
  height: var(--banner-top-height);
  text-align: center;

  @media( max-width: 768px ) {
    min-height: 164px;
    height: auto;
  }
}

.ads-text-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  flex-grow: 1;
  font-size: 0.8rem;
  color: inherit;
  cursor: pointer;

  svg {
    color: inherit;
    font-size: 1rem;
    margin-right: 0.5rem;
  }
}

.annons-middle {
  width: 100%;
  display: grid;
  grid-template-columns: 220px auto calc(160px + 1rem);
  background: #fff;
  color: #333;
  border: 1px solid var(--bordergrey);
  padding: 2px;
  margin: 1rem 0 0.3rem 0;
  box-sizing: border-box;
}

.annonserna {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: center;
  gap: 2rem 2rem;
  padding: 1rem 0;

  @media( min-width: 768px ) {
    height: fit-content;
  }

  &.rader {
    grid-template-columns: 100%;
    gap: 1rem 1rem;
  }
}

.home-header {
  display: unset !important;
}

.annons-wrapper-sing {
  padding: 10px;
  width: 100%;
  max-width: 100%;
  padding: 5px;
  // background-color:rgb(231, 231, 231);
  border: 1px solid var(--bordergrey);
  box-sizing: border-box;

  .annons-title,
  .annons-price {
    padding-left: 10px;
  }
}

.annons-wrapper-three {
  padding: 5px;
  //float: left;
  //width:300px;
  background-color:#fff;
  transition: 0.2s;
}

.home-content {
  width: 100%;
  display: grid;
  grid-template-columns: 220px minmax(50vw,100vw) calc(160px + 1rem);

  .left {

  }
}

.home-content-container-banner-right {
  margin: 1rem 0 0 0;

  div[adunit="104"] {
    position: sticky;
    top: 5rem;
  }
}

.home-content-container-banner-left {
  margin: 1rem 0 0 0;
  position: sticky;
  top: 5rem;
  display: flex;
  justify-content: center;
}

.home-midbanner {
  // display: flex;
  justify-content: center;
  align-content: center;
  grid-column: span 3;
  min-height: 100px;
  width: 100%;
  text-align: center;

  img {
    width: 100%;
  }

  @media( max-width: 1350px ) {
    grid-column: span 2;
  }
}

.annonserna.rader .home-midbanner {
  grid-column: 1;
}

.annons-container {
  display: flex;
  background-color: white;
  height: 150px;
  margin: 0 auto;
  border-radius: 2px;
}

.annons-container-three {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 2px;
  transition: 0.3s;

  .frame-grid {
    overflow: hidden;
  }
}

// .annons-container:hover,
// .annons-container-three:hover {
//   box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.3);
// }

.annons-wrapper-three:hover {
  background-color: var(--mgrey);
}

.homepage-ad {
  box-sizing: border-box;
  border: 1px solid var(--bordergrey);
  height: fit-content;
}

.annons-small-text {
  font-size: 12px;
  color: rgb(54, 52, 51);
  letter-spacing: 0.9px;
  float: left;
}

.annons-latest-published {
  float: right;
  font-size: 12px;
  color: inherit;
  letter-spacing: 1px;
  padding-right: 10px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin: 3px;
}

.ad-column-left {
  height: 200px;
  display: inline-block;
  background-color: blue;
  width: 35%;
  height: 200px;
  text-align: center;
}

.ad-column-information {
  flex-grow: 1;
  height: 100%;
  padding: 1.5rem;
}

.ad-column-information-grid {
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.annons-title {
  font-size: 1.3rem;
  display: block;
  padding: 20px 0 20px 20px;
  letter-spacing: 0.9px;
}

.annons-title-grid {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  display: block;
}

.annons-price {
  color: rgb(234, 85, 38);
  display: block;
  font-style: bold;
  font-size: 1.1rem;
  padding-top: 20px;
  padding-left: 20px;
  font-style: bold;
  font-weight: bold;
  padding-bottom: 40px;
  letter-spacing: 1.1px;
}

.annons-price-grid {
  display: block;
  padding: 6px 0 0 0;
  font-size: 0.9rem;
}

.annons-info {
  font-size: 1rem;
  line-height: 1rem;
  padding: 0 0 0 20px;
  color: #505050;
}

.annons-info-grid {
  color: #505050;
  font-size: 0.68rem;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  //display:flex;
  //padding-right: 12px;
}

.annons-list {
  color: #5c5c5c;
  background-color: #dbd9d9;
  border: 1px solid #fff;
  border-top: 0;
  border-bottom: 0;
  display: inline;
  cursor: pointer;
  padding: 10px 12px 10px 12px;
  border-radius: 3px;
}

.annons-list:hover {
  background-color: lightgrey;
}

.next-buttons {
  padding: 15px 20px 20px 0px;
  text-align: center;
}

.annons-next-icon {
  vertical-align: middle;
}

.homepage-info-icon {
  width: 100px;
  font-size: 40px;
}

.annons-ads-left {
  margin: 10px;
  border: 1px solid lightgrey;
  background-color: white;
  color: rgb(133, 131, 131);
  position: sticky;
  left: 14%;
  width: var(--banner-side-width);
  text-align: center;
}

.annons-ads-text {
  font-size: 12px;
  color: #363433;
  margin: 10px;
  letter-spacing: 1px;
}

.annons-ads-right {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid lightgrey;
  background-color: white;
  color: #858383;
  position: sticky;
  right: 15%;
  width: 200px;
  text-align: center;
}

.annons-sum-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  justify-content: center;
}

.annons-sum-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  color: inherit;
}

.annons-sum-ads {
  font-size: 12px;
  padding-left: 10px;
  letter-spacing: 1px;
  line-height: 35px;
}

.annons-sum-published {
}

.home-basic-grid {
  vertical-align: middle;
  cursor: pointer;
  color: inherit;
  padding: 1px;
  margin: 0 10px 0 20px;
  width: 20px;
  height: 20px;
}

.home-grid {
  vertical-align: middle;
  cursor: pointer;
  color: inherit;
  padding: 1px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.home-basic-grid.active,
.home-grid.active {
  box-shadow: inset 0 0 0 1px var(--dorange);
}

.column11 {
  border: 1px solid red;
  width: 100%;
  max-width: 900px;
  height: 200px;
  margin: 0 auto;
}

.column2 {
  background-color: blue;
  width: 35%;
  height: 200px;
  text-align: center;
}

.square {
  height: 200px;
  width: auto;
}

.frame {
  height: 150px;
  max-width: 300px;
}

.annons-wrapper-sing .annons-container .frame .ad-img {
  height: 150px;
  width: 110px;
}

.ad-img {
  height: var(--brickimgheight);
  width: 100%;
  object-fit: cover;
}

.home-banner-left {
  position: fixed;
  left: 4%;
  top: 34%;
}

.banner-left {
  float: left;
}

.home-banner-right {
  position: fixed;
  right: 4%;
  top: 34%;
}

.home-banner-left-scroll {
  position: fixed;
  left: 4%;
  top: 6%;
}

.home-banner-right-scroll {
  position: fixed;
  right: 4%;
  top: 6%;
}

.treddyPush {
  text-align: center;
  padding: 0 1rem 0 1rem;
  margin: 1rem 2rem 0 2rem;
  border: 1px solid #155d64;
  line-height: 1.5;
  font-size: 0.9rem;

  h4 {
    font-size: 1.1rem;
    margin: 0.5rem 0;
    display: inline-block;
  }

  img {
    height: 1em;
    transform: translate(2px, 5px);
    margin-right: 2px;
  }

  a {
    color: #155d64;
  }

  .treddyInfo {
    display: none;
  }

  .treddyInfoLink {
    font-weight: normal;
    font-size: 0.8em;
  }

  @media( max-width: 40rem) {
    margin: 1rem 0.7rem 0 0.7rem;
    
    h4 {
      font-size: 0.9rem;
    }

    img {
      margin-right: 3px;
    }
  }
}

.adSideContainer {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: end;
  justify-content: center;

  .treddycontainer {
    display: inline-block;
    margin: 0 0 0 0.3rem;

    .treddyboll {
      height: 0.6rem;
      margin-right: 3px;
    }

    @media(max-width: 40rem) {
      margin: 0;
      position: absolute;
      right: 0;

      span {
        display: none;
      }
    }
  }
}

.adSideContainer .adSide.left {
  //width: 40%;
}

.adSideContainer .adSide.right {
  //width: 60%;
  text-align: right;
}

.home-banner-container {
  display: flex;
  // max-width: var(--maxwidth);
  // margin: 0 auto;
  width: 100%;

  .home-content-container-banner-right {
    justify-self: flex-end;
  }
}

.home-category-description-wrapper {
  max-width: var(--avgwidth);
  margin: 2rem auto;
  padding: 0 1rem;
  box-sizing: border-box;
  
  .home-category-description {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 450px) {
  .home-content {
    justify-content: unset;
    grid-template-columns: 0 100vw 0;
  }

  .annons-sum-ads {
    display: none;
  }

  .annons-title {
    padding: 10px 0 5px 10px;
    font-size: 0.7rem;
  }

  .ad-column-information-grid {
    float: none;
  }

  .annons-price {
    padding: 1px 0 5px 10px;
    font-size: 0.6rem;
  }

  .annons-price-grid {
    letter-spacing: normal;
    padding: 0;
  }

  .annons-info {
    font-size: 10px;
    letter-spacing: normal;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 0 10px;
  }

  .annons-title-grid {
    font-size: 0.8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
  }

  .annons-info-grid {
    font-size: 0.55rem;
    letter-spacing: normal;
    padding-top: 3px;
  }

  .ad-column-information-grid {
    padding: 10px 10px 10px 15px;
  }

  .next-buttons {
    padding: 10px;
  }

  .frame {
    height: 100px;
    width: fit-content;
    max-width: 50%;
  }

  .frame-grid {
    height: 100px;
  }

  .ad-img {
    object-position: top center;
    max-height: 100%;
  }

  .adSideContainer {
    grid-template-columns: 100%;
  }

  .adSideContainer .adSide.right {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .annons-container,
  .annons-middle,
  .home-container {
    width: 100%;
  }

  .ad-column-left {
    width: 45%;
  }

  .ad-column-information {
    text-align: left;
    padding: 10px 0 10px 0;
    // height: 120px;
  }

  .annons-title {
    padding: 10px 0 5px 20px;
    font-size: 0.8rem;
  }

  .annons-price {
    padding-bottom: 3px;
    font-size: 0.7rem;
    padding-top: 3px;
  }

  .annons-info {
    font-size: 10px;
    letter-spacing: normal;
  }

  .next-buttons {
    padding: 10px;
  }

  .frame {
    height: 100px;
    width: fit-content;
    max-width: 50%;
  }

  .annons-container {
    height: 100px;
  }
}

@media screen and (max-width: 820px) {
  .annons-price-grid {
    font-size: 0.8rem;
    padding-top: 5px;
  }

  .ad-column-information-grid{
    width: auto;
  }

  .annonserna {
    gap: 0.5rem;
    padding: 0.5rem 0;
  }

  .annons-info-grid {
    font-size: 0.60rem;
    letter-spacing: normal;
    padding-top: 3px;
    display: block;
    position: relative;
  }

  .ad-column-information-grid {
    padding: 5px;
  }

  .next-buttons {
    padding: 10px;
  }

  .frame {
    height: 100px;
    width: fit-content;
    max-width: 50%;
  }

  .frame-grid {
    height: 120px;
  }

  .adSideContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 759px) {
  .annons-small-text {
    font-size: 11px;
  }

  .annons-wrapper-three {
    padding: 4px;
  }

  .adLoadingSkeleton.annons-wrapper-three {
    height: 184px;
  }
}

@media screen and (max-width: 1350px) {
  .annonserna {
    grid-template-columns: 50% 50%;
    padding: 0.5rem 0.8rem;
  }
}

@media screen and (min-width: 759px) and (max-width: 1350px) {
  .annons-container,
  .annons-middle {
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: ease-in;
  }

  .home-container {
    max-width: 900px;
  }

  .annonserna {
    grid-template-columns: 45% 45%;
  }
}

.adLoadingSkeleton.MuiSkeleton-text {
  transform: scale(1, 1);
}

@media screen and (min-width: 769px ) {
  .adLoadingSkeleton.annons-wrapper-three {
    height: 323px;
  }
}

@media screen and (max-width: 768px) {
  .annons-middle {
    grid-template-columns: unset;
    margin: 0.5rem 0.5rem 0 0.5rem;
    width: unset;
  }
  
  .annons-sum-container {
    padding: 0;
  }

  .annons-sum-wrapper {
    grid-template-columns: unset;
  }
  
  .home-content-container-banner-left,
  .home-content-container-banner-right,
  .ads-text-link {
    display: none;
  }

  .home-content-container,
  .annons-wrapper-sing {
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .annons-sum-container {
    max-width: 875px;
  }
}
@media screen and (max-width: 1300px) {
  .head-banner-first {
    width: 100%;
  }

  .home-content-container-banner-right,
  .ads-text-right {
    margin-right: 15px;
  }

  .home-content-container-banner-left,
  .ads-text-left {
    margin-left: 15px;
  }
}
